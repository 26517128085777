import React, { useState } from 'react';
import './EmailModal.css';
import emailjs from 'emailjs-com';
import { IoIosClose } from "react-icons/io";
import { FaDiscord } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { SiBandcamp } from "react-icons/si";
import { SiSoundcloud } from "react-icons/si";
import { IoMdSend } from "react-icons/io";

const EmailModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        subject: '',
        message: ''
    });
    const SERVICE_ID = 'service_llqlwsn';
    const TEMPLATE_ID = 'template_msk7xxi';
    const PUBLIC_API_KEY = '3hgduZdhT6LMsjxUR';

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.send(SERVICE_ID, TEMPLATE_ID, formData, PUBLIC_API_KEY)
            .then((result) => {
                console.log('Email sent successfully:', result.text);
                alert('Message sent!');
            }, (error) => {
                console.log('Failed to send email:', error.text);
                console.log(SERVICE_ID, TEMPLATE_ID, PUBLIC_API_KEY)
                alert('Failed to send message. Please try again later.');
            });

        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            subject: '',
            message: ''
        });
        onClose();
    };

    if (!isOpen) return null;

    return (
        <div className="email-overlay">
            <div className="email-content" onClick={(e) => e.stopPropagation()}>
                <div className='email-header'>
                    <h2>Get in touch!</h2>
                    <button className="email-close-button" onClick={onClose}><IoIosClose /></button>
                </div>
                <p>Send me an email, or message me via my socials.</p>
                <form onSubmit={sendEmail}>
                    <div className='name-fields'>
                        <input
                            type="text"
                            name="firstName"
                            placeholder="First Name..."
                            value={formData.firstName}
                            onChange={handleChange}
                            required
                        />
                        <br />
                        <input
                            type="text"
                            name="lastName"
                            placeholder="Last Name..."
                            value={formData.lastName}
                            onChange={handleChange}
                        />
                        <br />
                    </div>
                    <input
                        type="email"
                        name="email"
                        placeholder="Email..."
                        value={formData.email}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <input
                        type="text"
                        name="subject"
                        placeholder="Subject..."
                        value={formData.subject}
                        onChange={handleChange}
                        required
                    />
                    <br />
                    <textarea
                        className='email-message'
                        name="message"
                        placeholder="Message..."
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                    <br />
                    <div className='email-buttons'>
                        <button type="submit"><IoMdSend /></button>
                        
                    </div>
                </form>
                <div className='social-buttons'>
                    <a href="https://discord.com/invite/KNXKQkEp" className='discord-icon' target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
                    <a href="https://www.linkedin.com/in/mightymuso/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
                    <a href="https://mightymuso.bandcamp.com" target="_blank" rel="noopener noreferrer"><SiBandcamp /></a>
                    <a href="https://soundcloud.com/mightymuso" target="_blank" rel="noopener noreferrer"><SiSoundcloud /></a>
                </div>
            </div>
        </div>
    );
};

export default EmailModal;
