import React from 'react';
import Header from './components/Header';
import ProjectGallery from './components/ProjectGallery';
import Footer from './components/Footer';
import './App.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import ShowreelsContainer from './components/ShowreelsContainer';

function App() {
  return (
    <div className="App">
      <Header />
      <main>
        <ShowreelsContainer />
        <ProjectGallery />
      </main>
      <Footer />
    </div>
  );
}

export default App;
