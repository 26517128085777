const projects = [
    {
        title: 'power sink',
        publisher: 'winterwire games',
        roles: [
            { role: 'composer' },
            { role: 'sound designer' },
            { role: 'fmod implementation' },
        ],
        published: 'TBA',
        description: 'In the depths of a decaying underwater power plant, you must guide the Diver down in order to restore power to their home and uncover the secrets held by the creaky grid that supports it.',
        banner: `${process.env.PUBLIC_URL}/images/banners/power-sink-banner.png`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/1.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/2.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/3.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/4.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/5.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/6.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/7.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/8.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/9.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/power-sink/10.jpg` },
        ],
        videos: [
            { name: 'trailer', url: 'https://www.youtube.com/embed/wtMIyUy98js?si=5L2eOIRwHi-xTXYN' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/-ZnJX1ShPOM?si=qGFPFvxrHagj9m-U' },
        ],
        audio: [
            { url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1680830433&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true' }
        ],
        link: 'https://store.steampowered.com/app/2289740/Power_Sink/'
    },
    {
        title: 'span',
        publisher: 'space base place',
        roles: [
            { role: 'composer' },
            { role: 'fmod implementation' }
        ],
        published: 'TBA',
        description: 'Explore, create, and defend humanity from the machine onslaught in this fast-paced survival adventure. Navigate an ancient space megastructure, master a powerful tether system for traversal and combat, and use a detailed building system to construct epic bases. Play solo or with friends in co-op multiplayer, enduring hazardous environments and relentless AI-controlled sieges as you race to uncover the mysteries of the SPAN.',
        banner: `${process.env.PUBLIC_URL}/images/banners/span-banner.jpg`,
        videos: [
            { name: 'trailer', url: 'https://www.youtube.com/embed/QumUPzD-HTU?si=r7BFcNSjX8RP4l37' },
            { name: 'gameplay', url: 'https://www.youtube.com/embed/64I8HEUi024?si=PA-Rp-Khz3IQZncy' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/P4PXOTzQRaY?si=NGYCtaLooXSOVXLw' },
        ],
        audio: [
            { url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1988678028&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true'}
        ],
        link: ''
    },
    {
        title: 'misc. a tiny tale',
        publisher: 'tinyware games',
        roles: [
            { role: 'voice actor' }
        ],
        published: 'TBA',
        description: 'Embark on an adventure as a tiny robot made of miscellaneous parts. Collect trash, coins, cogs, and more as you help friends around you. Make the world a better place, one tiny task at a time.',
        banner: `${process.env.PUBLIC_URL}/images/banners/misc-banner.jpg`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/1.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/2.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/3.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/4.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/5.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/6.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/7.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/8.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/9.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/misc/10.jpg` },
        ],
        videos: [
            { name: 'trailer', url: 'https://www.youtube.com/embed/8_yVRukOfoc?si=DmHacCvnCAll98ZD' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: '' },
        ],
        link: 'https://store.steampowered.com/app/1308940/Misc_A_Tiny_Tale/'
    },
    {
        title: 'fabulous crisis',
        publisher: 'aramis marcuola-bel',
        roles: [
            { role: 'composer' },
            { role: 'koreographer editing' }
        ],
        published: 'TBA',
        description: 'A VR rhythm game in early development inspired by classic rhythm games such as Space Channel 5, Parappa the Rapper, and Dance Dance Revolution.',
        banner: `${process.env.PUBLIC_URL}/images/banners/fc-banner.png`,
        videos: [
            { name: 'trailer', url: '' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/715gmNpYNhg?si=6X9W8SYOVLn_zqE9' },
        ],
        link: ''
    },
    {
        title: 'project break//down',
        publisher: 'fist face studios',
        roles: [
            { role: 'composer' }
        ],
        published: 'TBA',
        description: 'Project: Break//Down is an experimental 3D action-adventure brawler with a unique limb-based combat system that allows for satisfying combat. In this game you explore the EZON complex, a mass production and distribution facility secretly gone rogue in the shoes of 2 fools.',
        banner: `${process.env.PUBLIC_URL}/images/banners/pbd-banner.jpg`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/1.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/2.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/3.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/4.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/5.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/6.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/7.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/project-break-down/8.jpg` },
        ],
        videos: [
            { name: 'trailer', url: '' },
            { name: 'gameplay', url: 'https://www.youtube.com/embed/nBJnDgGC50A?si=2Jg5DbEV92skKrCR' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/Yu9n_ORJohU?si=Rd8le1EaFJxGfRNJ' },
        ],
        link: 'https://store.steampowered.com/app/2317220/Project_BreakDown/'
    },
    {
        title: 'night of the living ooze',
        publisher: 'winterwire games',
        roles: [
            { role: 'composer' },
            { role: 'sound designer' },
            { role: 'voice actor' },
            { role: 'fmod implementation' },
        ],
        published: '21.08.2024',
        description: 'You play as a living ooze that hungers for all, flings chunks of itself at enemies, and thirsts for the size to consume the entire universe.',
        banner: `${process.env.PUBLIC_URL}/images/banners/notlo-banner.png`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/notlo/1.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/notlo/2.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/notlo/3.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/notlo/4.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/notlo/5.jpg` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/notlo/6.jpg` },
        ],
        videos: [
            { name: 'trailer', url: 'https://www.youtube.com/embed/hBZbkPj30Uo?si=JO_S_Bj8H0CgXwLU' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/bcVBGv-Uemw?si=fA6xzxnhJpZX57yB' },
        ],
        link: 'https://winterwiregames.itch.io/night-of-the-living-ooze'
    },
    {
        title: 'surely you jest?!',
        publisher: 'winterwire games',
        roles: [
            { role: 'composer' },
            { role: 'sound designer' },
            { role: 'voice actor' },
            { role: 'fmod implementation' },
        ],
        published: '28.01.2024',
        description: "Surely you Jest?! is a Physics-Based extravaganza of gags, pranks, and more as you, The King's court Jester, are commanded to make the King and his guests laugh till their sides hurt.",
        banner: `${process.env.PUBLIC_URL}/images/banners/syj-banner.png`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/syj/1.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/syj/2.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/syj/3.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/syj/4.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/syj/5.png` },
        ],
        videos: [
            { name: 'trailer', url: 'https://www.youtube.com/embed/rhylxlBMGeE?si=o6c8m8thiDD1ZgC_' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/9G3z4VSCyGU?si=HXSLGvOffRJNpQ3Os' },
        ],
        link: 'https://winterwiregames.itch.io/surely-you-jest'
    },
    {
        title: 'a balanced breakfast',
        publisher: 'winterwire games',
        roles: [
            { role: 'composer' },
            { role: 'sound designer' },
            { role: 'voice actor' },
            { role: 'fmod implementation' },
        ],
        published: '10.07.2023',
        description: "Help your dear old grannie make some breakfast in this Role-Reversed Puzzle Game filled with stylish pixel art, bopping chip-tune beats, and just the right amount of challenge!",
        banner: `${process.env.PUBLIC_URL}/images/banners/aba-banner.png`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/breakfast/1.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/breakfast/2.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/breakfast/3.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/breakfast/4.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/breakfast/5.png` },
        ],
        videos: [
            { name: 'trailer', url: '' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/FCCmN_aNIso?si=0eP7E6ZFhwWNsHe6' },
        ],
        link: 'https://winterwiregames.itch.io/a-balanced-breakfast'
    },
    {
        title: 'jimmy blitz and the rocket dice',
        publisher: 'team dovecoat',
        roles: [
            { role: 'composer' }
        ],
        published: '18.07.2022',
        description: "We’re pullin’ the biggest casino heist in history, boss. Let’s dominate some games and take home the money with your ROCKET-POWERED DICE!",
        banner: `${process.env.PUBLIC_URL}/images/banners/jbrd-banner.png`,
        screenshots: [
            { url: `${process.env.PUBLIC_URL}/images/screenshots/jimmy-blitz/1.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/jimmy-blitz/2.png` },
            { url: `${process.env.PUBLIC_URL}/images/screenshots/jimmy-blitz/3.png` },
        ],
        videos: [
            { name: 'trailer', url: 'https://www.youtube.com/embed/cDXtcY1boXo?si=1_Db3AkJjevwRbAQ' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: '' },
        ],
        link: 'https://thegoodowl.itch.io/rocketdice'
    },
    {
        title: 'nest quest',
        publisher: 'team dovecoat',
        roles: [
            { role: 'composer' }
        ],
        published: '25.07.2021',
        description: "Time to get crackin'! Roll, bounce, and fling yourself back to your nest in over 15 egg-citing levels!",
        banner: `${process.env.PUBLIC_URL}/images/banners/nestquest-banner.png`,
        videos: [
            { name: 'trailer', url: '' },
            { name: 'gameplay', url: '' },
            { name: 'showreel', url: 'https://www.youtube.com/embed/Rltmkcv5dsU?si=Q5q1pO-jXJufTfAR' },
        ],
        link: 'https://www.newgrounds.com/portal/view/807185'
    },
];

export default projects;
