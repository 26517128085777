import React, { useState, useEffect } from 'react';
import './Footer.css';
import EmailModal from './EmailModal';
import { FaDiscord } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { SiBandcamp } from "react-icons/si";
import { SiSoundcloud } from "react-icons/si";

function Footer() {
  const mobileScreenSize = 768;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= mobileScreenSize);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= mobileScreenSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isEmailModalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isEmailModalOpen]);

  const openEmailModal = () => {
    setIsEmailModalOpen(true);
  };

  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  return (
    <footer className="footer">
      {/* TODO: Change date to automatically update year */}
      <p className='copyright'>&copy; 2025 MIGHTY MUSO</p>
      {!isSmallScreen && (
        <div className='socials'>
          <a href="https://discord.com/invite/KNXKQkEp" className='discord-icon' target="_blank" rel="noopener noreferrer"><FaDiscord /></a>
          <a href="https://www.linkedin.com/in/mightymuso/" target="_blank" rel="noopener noreferrer"><FaLinkedinIn /></a>
          <a href="https://mightymuso.bandcamp.com" target="_blank" rel="noopener noreferrer"><SiBandcamp /></a>
          <a href="https://soundcloud.com/mightymuso" target="_blank" rel="noopener noreferrer"><SiSoundcloud /></a>
        </div>)}
      {isSmallScreen && (
        <a href='#' className='contact-button' onClick={openEmailModal}>CONTACT</a>
      )}
      <EmailModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />
    </footer>
  );
}

// TODO: Position copyright onto far left for smaller screens

export default Footer;