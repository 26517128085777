import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import './ShowreelsModal.css';
import { IoIosClose } from 'react-icons/io';
import showreels from '../data/showreels';

const ShowreelsModal = ({ isOpen, onClose }) => {
  const mobileScreenSize = 768;
  const [slidesPerView, setSlidesPerView] = useState(window.innerWidth > mobileScreenSize ? 2 : 1);

  const showreel = showreels.map(({ video, alt }, index) => (
    <div className='video-wrapper' key={index}>
      <iframe
        src={video}
        title={alt}
        frameBorder={0}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  )
  )

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.innerWidth > mobileScreenSize ? 2 : 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <div className="showreels-overlay" >
      <div className='showreels'>
        {showreel}
      </div>
      <div className="close-bar">
        <button className="close-bar-button" onClick={onClose}>
          <IoIosClose />
        </button>
      </div>
    </div>
  );
};

export default ShowreelsModal;