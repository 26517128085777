import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import './Modal.css';
import { Navigation } from "swiper/modules";
import { IoLogoGameControllerB } from 'react-icons/io';
import { IoIosClose } from 'react-icons/io';

const Modal = ({ isOpen, onClose, project }) => {
  const mobileScreenSize = 768;
  const [slidesPerView, setSlidesPerView] = useState(window.innerWidth > mobileScreenSize ? 3 : 1);

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(window.innerWidth > mobileScreenSize ? 3 : 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  if (!project) return null;
  return (
    <div className="project-overlay" onClick={onClose}>
      <div className="project-content" onClick={(e) => e.stopPropagation()}>
        <div className='project-details'>

          {project.link ? (
            <a href={project.link}>
            <img src={project.banner} alt={project.title} className='project-banner' />
          </a>
          ) : (
            <img src={project.banner} alt={project.title} className='project-banner' />
          )}
          <div className='title-link'>
            <h2>{project.title}</h2>
            {project.link ? (
              <a href={project.link} className='project-url' target="_blank" rel="noopener noreferrer">
                <IoLogoGameControllerB />
              </a>
            ) : (
              <span style={{ cursor: 'not-allowed', opacity: 0.2, fontSize: 50 }}>
                <IoLogoGameControllerB />
              </span>
            )}
          </div>
          <div className='release-date'>
            <h3 className='denominator-title'>Published</h3>
            <h3 className='denominator-text'>{project.published}</h3>
          </div>
          <div className='developer'>
            <h3 className='denominator-title'>Developer</h3>
            <h3 className='denominator-text'>{project.publisher}</h3>
          </div>
          <div className='role'>
            <h3 className='role-title'>Role</h3>
            <div className='role-container'>
              {project.roles.map((roles, index) => (
                <h3 className='role-text' key={index}>{roles.role}</h3>
              ))}
            </div>
          </div>
          <p className='description'>{project.description}</p>
        </div>
        <div className='project-media'>
          {project.screenshots && project.screenshots.length > 0 && (
            <div className='project-screenshots'>
              <Swiper
                modules={[Navigation]}
                spaceBetween={10}
                slidesPerView={slidesPerView}
                navigation
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
              >
                {project.screenshots.map((screenshot, index) => (
                  <SwiperSlide key={index}>
                    <img src={screenshot.url} alt={`Screenshot ${index + 1}`} className="screenshot-image" />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
          <div className='audio-video-container'>
            {project.audio && project.audio.length > 0 && (
              project.audio
                .filter(audio => audio.url)
                .map((audio, index) => (
                  <div className="soundcloud-wrapper" key={index}>
                    <iframe
                      width="100%"
                      height="100%"
                      allow="autoplay"
                      frameBorder="none"
                      color="#FE6148"
                      overflow="scroll"
                      style={{
                        overflow: 'scroll',
                      }}
                      src={audio.url}
                    ></iframe>
                  </div>
                ))
            )}
            {project.videos && project.videos.length > 0 && (
              project.videos
                .filter(video => video.url)
                .map((video, index) => (
                  <div className="video-wrapper" key={index}>
                    <iframe
                      width="100%"
                      height="100%"
                      src={video.url}
                      title={`${project.title} ${video.name}`}
                      frameBorder="none"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )
                ))}
          </div>
        </div>
      </div>
      <div className="close-bar">
        <button className="close-bar-button" onClick={onClose}>
          <IoIosClose />
        </button>
      </div>
    </div>
  );
};

export default Modal;