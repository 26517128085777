import { React, useEffect, useState } from 'react';
import './Header.css';
import EmailModal from './EmailModal';

function Header() {
  const mobileScreenSize = 768;
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > mobileScreenSize);
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > mobileScreenSize);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const openEmailModal = () => {
    setIsEmailModalOpen(true);
  };

  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  return (
    <header className="header">
      <div className='site-profile-logo'>
        {/* <img src={`${process.env.PUBLIC_URL}/images/profile/muso-profile.png`} alt='muso-profile' className='profile' /> */}
        <img src={`${process.env.PUBLIC_URL}/images/profile/muso-logo-text.png`} alt='muso-logo' className='logo' />
      </div>
      {isLargeScreen && (
        <div className='contact-button'>
          <a href='#' onClick={openEmailModal}>CONTACT</a>
        </div>
      )}
      <EmailModal isOpen={isEmailModalOpen} onClose={closeEmailModal} />
    </header>
  );
}

export default Header;
