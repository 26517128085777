import React, { useState, useEffect } from 'react';
import "./ShowreelsContainer.css";
import ShowreelsModal from "./ShowreelsModal.js";

const ShowreelsContainer = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    const detectTouchDevice = () => {
      setIsTouchDevice('ontouchstart' in window || navigator.maxTouchPoints > 0);
    };

    detectTouchDevice();
    window.addEventListener('resize', detectTouchDevice);

    return () => {
      window.removeEventListener('resize', detectTouchDevice);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = (e) => {
    e.stopPropagation(); // Prevent clicks inside modal from reopening it
    setIsModalOpen(false);
  };

  return (
    <div className="showreels-container">
      <div className="image-wrapper" onClick={openModal}>
        <img
          src={`${process.env.PUBLIC_URL}/images/thumbnails/showreels-thumbnail.png`}
          alt="Showreel Preview"
          className="showreels-thumbnail"
        />
        {!isTouchDevice && (
          <div className="overlay">
            <p>-view-</p>
          </div>
        )}
      </div>
      {isModalOpen && <ShowreelsModal isOpen={isModalOpen} onClose={closeModal} />}
    </div>
  );
};

export default ShowreelsContainer;
